<template>
  <div class="edit-comment">
    <div class="edit-comment__user-image">
      <img :src="profilePicture" alt="User image" />
    </div>
    <div class="edit-comment__container">
      <div class="btn-textarea">
        <textarea
        data-cy="storyComment"
          id=""
          rows="2"
          placeholder="Write a comment..."
          ref="replyComment"
          v-model="newComment"
        ></textarea>
        <the-loader v-if="loading" class="comment-textarea-loader"></the-loader>
        <button v-if="commentFor == 'impact'" class="send-btn">
          <i data-cy="updateImpactStoryComment" class="icon icon-send" @click="updateImpactComment()"></i>
        </button>
        <button v-if="commentFor == 'discover'" class="send-btn">
          <i class="icon icon-send" @click="updateDiscoverComment()"></i>
        </button>
        <button v-if="commentFor == 'topics'" class="send-btn">
          <i class="icon icon-send" @click="updateDiscussionComment()"></i>
        </button>
        <button v-if="commentFor == 'saved-folder'" class="send-btn">
          <i class="icon icon-send" @click="updateSavedFolderComment()"></i>
        </button>
        <button v-if="commentFor == 'folders'" class="send-btn">
          <i class="icon icon-send" @click="updateFolderComment()"></i>
        </button>
      </div>
      <button  data-cy="cancelUpdateStory" @click="closeCommentBox" class="cancel-btn">Cancel</button>
    </div>
  </div>
</template>

<script>
import TheLoader from "../TheLoader.vue";
export default {
  components: {
    TheLoader
  },
  props: {
    profilePicture: String,
    comment: String,
    commentId: String,
    storyId: String,
    parentCommentId: String,
    commentFor: String,
    isPrivateTopic:Boolean,
    interestGroupId:String,
  },
  emits: ["close-comment-box"],
  data() {
    return {
      newComment: "",
      loading: false,
    };
  },
  mounted() {
    this.newComment = this.comment;
  },
  computed: {
    // interestGroupId(){
    //   return this.$store.getters["article/getInterestGroupId"];
    // }
    allFolderdiscussions() {
      return this.$store.getters["folders/getFolderComments"].results;
    },
  },
  methods: {
    async updateImpactComment() {
      try {
        this.loading = true;
        const payload = {
          comment: this.newComment,
          comment_id: this.commentId,
          story_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "impact/updateImpactArticleComment",
          payload
        );
        if (response.status === 200) {
          await this.$store.dispatch(
            "impact/fetchImpactArticleComments",
            this.storyId
          );
          this.closeCommentBox();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateDiscoverComment() {
      try {
        this.loading = true;
        const payload = {
          comment: this.newComment,
          comment_id: this.commentId,
          article_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "discover/updateDiscoverArticleComment",
          payload
        );
        if (response.status === 200) {
          await this.$store.dispatch(
            "discover/fetchDiscoverArticleComments",
            this.storyId
          );
          this.closeCommentBox();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateDiscussionComment(){
      try {
        this.loading = true;
        const payload = {
          comment: this.newComment,
          discussion_id: this.commentId,
          interest_id: this.storyId,
          interest_group_id: this.interestGroupId,
        };
        const response = await this.$store.dispatch(
          "discover/updateDiscussionCommentst",
          payload
        );
        if (response.status === 200) {
          const payload = {
          interest_group_id: this.interestGroupId,
          interest_id: this.storyId
        }
          await this.$store.dispatch(
            this.isPrivateTopic? "discussion/setPrivateDiscussionComments" : "discussion/setPublicDiscussionComments",
            payload
          );
          this.closeCommentBox();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateSavedFolderComment() {
      try {
        this.loading = true;
        const payload = {
          comment: this.newComment,
          comment_id: this.commentId,
          folder_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "profile/updateSavedFolderComment",
          payload
        );
        if (response.status === 200) {
          await this.$store.dispatch(
            "profile/fetchSavedFolderComments",
            this.storyId
          );
          this.closeCommentBox();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateFolderComment() {
      try {
        this.loading = true;
        const form = new FormData();
        form.append("comment", this.newComment);
        const payload = {
          comment: form,
          commentId: this.commentId,
          folderId: this.storyId,
        };
        const response = await this.$store.dispatch(
          "folders/updateFolderCommentst",
          payload
        );
        if (response.status === 200) {
          this.closeCommentBox();
          const commentIndex = this.allFolderdiscussions.findIndex(obj => obj.id === this.commentId);
          this.allFolderdiscussions[commentIndex] = response.data;
          
          if (this.parentCommentId) {
            const parentIndex = this.allFolderdiscussions.findIndex(val => val.id === this.parentCommentId);
            const childIndex = this.allFolderdiscussions[parentIndex].child.findIndex(child => child.id === this.commentId);
            this.allFolderdiscussions[parentIndex].child[childIndex] = response.data;
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    closeCommentBox() {
      this.$emit("close-comment-box", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-comment {
  display: flex;
  align-items: flex-start;
  width: auto;
  &__user-image {
    width: 3rem;
    height: auto;
    border-radius: 100%;
    margin-top: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  &__container {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    .btn-textarea {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff57;
      padding: 1rem 1rem;
      margin-bottom: 0.2rem;
      textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.6rem;
        font-family: $font-primary;
        line-height: 1.8rem;
        resize: none;
        &::placeholder {
          opacity: 0.7;
        }
      }
      .send-btn {
        border: none;
        height: fit-content;
        width: fit-content;
        line-height: normal;
        margin-left: 1rem;
        cursor: pointer;
        background-color: transparent;
        .icon {
          color: $color-primary;
          font-size: 2rem;
        }
      }
    }
    .cancel-btn {
      border: none;
      background-color: transparent;
      color: $color-black;
      font-size: 1.2rem;
      font-family: $font-primary-bold;
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
</style>
