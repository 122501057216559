<template>
  <div class="comment-pdf-card">
    <img
      src="@/assets/icons/pdf-icon.png"
      alt="PDF Icon"
      class="comment-pdf-card__icon"
    />
    <div class="comment-pdf-card__content">
      <h1 class="comment-pdf-card__content--title">
        {{ pdfFileName }}
      </h1>
    </div>
    <div class="comment-pdf-card__buttons">
      <button @click="openPDFModal" class="comment-pdf-card__buttons--btn">
        <img src="@/assets/icons/eye-icon.png" />
      </button>
      <button @click="downloadPdf()" class="comment-pdf-card__buttons--btn">
        <img src="@/assets/icons/download-icon.png" />
      </button>
    </div>
  </div>
  <ViewPDFModal
    :visible="visiblePDFModal"
    :url="pdfUrl"
    :pdfFileName="pdfFileName"
    @closeModal="closePDBModal()"
  />
</template>

<script>
import ViewPDFModal from "./ViewPDFModal.vue";

export default {
  components: {
    ViewPDFModal,
  },
  props: {
    pdfUrl: String,
    pdfFileName: String,
  },
  data() {
    return {
      visiblePDFModal: false,
    };
  },
  methods: {
    openPDFModal() {
      this.visiblePDFModal = true;
    },
    closePDBModal() {
      this.visiblePDFModal = false;
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.pdfUrl;
      link.target = "_blank";
      link.download = this.pdfFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
.comment-pdf-card {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem !important;
  border: 1px solid $color-dark-grey-5;
  border-radius: 0.8rem;
  width: 25rem;
  max-width: 25rem;
  &__icon {
    width: 3rem;
    height: auto;
    margin-left: -0.6rem;
  }
  &__content {
    margin-left: 0.5rem;
    max-width: 50%;
    &--title {
      color: $color-black;
      font-size: 1.4rem;
      font-family: $font-primary-medium;
      line-height: 1.6rem;
      margin-bottom: 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
    &--btn {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-dark-grey-5;
      border-radius: 100% !important;
      outline: none;
      background-color: $color-white;
      cursor: pointer;

      img {
        width: 1.3rem;
        height: auto;
      }
    }
  }
}
</style>
